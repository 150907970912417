
window.start_toastie = ->
  return if window.toastie?.add? # .add is in the real toastie
  unless window.toastie_spot?
    tb = document.createElement('div')
    tb.setAttribute('id', 'toastie_spot')
    document.body.appendChild(tb)
  root = createRoot(window.toastie_spot)
  root.render(Toastie())

jready(start_toastie)


import Toastie from './jsx/toastie.js'

