
import { div, iframe, h4, button, em } from 'react-dom-factories'

VideoModalClass = createReactClass

  displayName: 'VideoModal'

  closeModal: (e) ->
    e?.preventDefault()
    @props.close_cb?()
    R.bs_modal.close()

  onPlayerStateChange: (event) ->
    if (event.data == YT.PlayerState.ENDED )
      setTimeout @closeModal, 200
    

  componentDidMount: ->
    if YT? # youtube api already loaded.
      window.playerx = new YT.Player('player', { events: { 'onStateChange': @onPlayerStateChange } })
    else # need to load youtube.
      # set up onYouTubeIframeAPIReady function called by yt iframe_api
      this_view = @
      window.onYouTubeIframeAPIReady = ->
        window.playerx = new YT.Player('player', { events: { 'onStateChange': this_view.onPlayerStateChange } })
      # load iframe_api
      tag = document.createElement('script')
      tag.id = 'yt_api_src'
      tag.src = 'https://www.youtube.com/iframe_api'
      firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

  render: ->
    title = @props.title or "Video"

    div { className: "modal-content video-modal" },
      div { className: "modal-header" },
        h4 { className: 'modal-title mr10' }, title
        em {}, "( please watch in HD High Quality and Full Screen! )"
        button { className: 'btn-close', type: "btn-button", 'aria-hidden': "true", onClick: @closeModal }
      div { className: "modal-body" },
        div { className: "yout ratio ratio-16x9"},
          iframe { id: "player",  className: "ratio-item", src: 'https://www.youtube.com/embed/' + @props.youtube_key + '?vq=1440p60&enablejsapi=1', enablejsapi: "1", allowFullScreen: true }
      div { className: 'modal-footer' },
        button { className: 'btn btn-sm btn-default', onClick: @closeModal }, 'Close'


VideoModal = React.createFactory VideoModalClass

# helper function wrapping call with bs_modal
# e.g. V.video_modal({ title: 'Intro..', youtube_key: 'TBeDjDdWTi4' })
V.video_modal = (args) -> R.bs_modal.open( title: args.title or 'Video...', body: VideoModal( args ) , size: 'modal-xl' )

export default VideoModal

