
# code from <head>
#
#      var _jr = []; var jready = function(f){ _jr.push(f) }


# expected to be called after jquery is loaded

clear_jready = ->
  if window._jr?.length
    f = window._jr.shift()
    f.call(document)
    setTimeout clear_jready, 10
  else
    window.jready = (f) -> f.call()
    window._jr = undefined

#
# not ready until DOMContentLoaded 
#
jQuery(document).ready ->
  setTimeout clear_jready, 10

