# general functions used by both external and internal(base)

import { div } from 'react-dom-factories'

$ = jQuery
$document = $(document)

$document.on 'click', "a.vt", (ev) ->
  $link = $(this)
  youtube_key =  $link.attr('data-id')
  title =  $link.attr('data-title')
  V.video_modal({ title: title, youtube_key: youtube_key })
  #_gaq.push [ "_trackEvent", "Video", "Play", $link.attr("data-video") ]
  ev.preventDefault()

$document.on 'click', "a.pic", (ev) ->
  $link = $(this)
  youtube_key =  $link.attr('data-id')
  title =  $link.attr('data-pic-title')
  x1 =  $link.attr('data-x1')
  x2 =  $link.attr('data-x2')
  alt =  $link.attr('data-alt')
  V.pic_modal({ title: title, x1: x1, x2: x2, alt: alt })
  #_gaq.push [ "_trackEvent", "Video", "Play", $link.attr("data-video") ]
  ev.preventDefault()

$document.on 'click', "button.tip_show", (ev) ->
  $this = $(this)
  window.j = $this
  tip_key = $this.closest('[data-tip_key]').attr('data-tip_key')
  #$this.toggleClass('active')
  #$("#tip-#{tip_key}").toggleClass('d-none')
  html_tip = $("#tip-#{tip_key}").html()
  modal_div =  div { className: 'modal-content', dangerouslySetInnerHTML: { __html: html_tip } }
  R.bs_modal.open( body: modal_div )
  ev.preventDefault()
  return false

$document.on 'change', ".currency_selector", "change", ->
  $this = $(this)
  document.location = document.location.pathname + "?set_currency=" + $this.val()

window.next_tick = ( cb ) -> setTimeout cb, 1

window.dev_env = -> Rinit?.env is 'development'

window.CSRFProtection = (xhr) ->
  token = $('meta[name="csrf-token"]').attr('content')
  if token
    xhr.setRequestHeader('X-CSRF-Token', token)
  if V.screen_id
    xhr.setRequestHeader('X-Screen-Id', V.screen_id)
  return true

window.supports_html5_storage = ->
  try
    if window["localStorage"]?
      return window["localStorage"]
  catch e
    console?.log("no local storage: #{e}")
    return false

window.localStorage_getItem = (key) -> localStorage.getItem(key)

window.localStorage_removeItem = (key) -> localStorage.removeItem(key)

window.localStorage_setItem = (key, value) ->
  try
    localStorage.setItem(key, value)
  catch err
    if (err.name).toUpperCase() == 'QUOTA_EXCEEDED_ERR'
      alert "You must have local storage enabled (or private browsing turned off)."

