
import { div, img, h4, button, picture } from 'react-dom-factories'

PicModalClass = createReactClass

  displayName: 'PicModal'

  closeModal: (e) ->
    e?.preventDefault()
    @props.close_cb?()
    R.bs_modal.close()

  render: ->
    title = @props.title or "Image"

    img_props = { src: @props.x1, className: 'ha' }
    if @props.x2?
      img_props.srcSet = "#{@props.x2} 2x"
    if @props.alt?
      img_props.alt = @props.alt

    div { className: "modal-content" },
      div { className: "modal-header" },
        h4 {}, title
        button { className: 'btn-close', type: "button", 'aria-hidden': "true", onClick: @closeModal }
      div { className: "modal-body tac" },
        img img_props
      div { className: 'modal-footer' },
        button { className: 'btn btn-sm btn-default', onClick: @closeModal }, 'Close'


PicModal = React.createFactory PicModalClass

# helper function wrapping call with bs_modal
# e.g. V.video_modal({ title: 'Intro..', youtube_key: 'TBeDjDdWTi4' })
V.pic_modal = (args) -> R.bs_modal.open( title: args.title or 'Image', body: PicModal( args ) , size: 'modal-xl' )

export default PicModal

