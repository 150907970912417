
# https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js/features/map'
import 'core-js/features/set'

import $ from 'jquery'

import createReactClass from 'create-react-class'

import _ from 'underscore'

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { createRoot } from 'react-dom/client'


window.$ = window.jQuery = $
window._ = _
window.React = React
window.ReactDOM = ReactDOM
window.PropTypes = PropTypes
window.createRoot = createRoot
window.createReactClass = createReactClass
window.fragment = React.createFactory(React.Fragment)



