
import { div, img } from 'react-dom-factories'

ToastClass = createReactClass
  displayName: 'Toast'

  remove: (ev) ->
    # allow links to work # ev.preventDefault()
    @props.parent.remove @props.toast.id

  componentDidMount: ->
    @props.toast.height = @refs.toast.clientHeight

  render: ->
    toast = @props.toast
    # toastie is used external but never with avatar_id so 
    # I think safe to reference V.view
    
    styles = { top: toast.top }
    
    div { ref: 'toast', className: "toastie toastie-#{toast.level}", onClick: @remove, style: styles },
      div { className: "toastie-col1" },
        if (avatar_id = toast.avatar_id)?
          img { className: "toastie-img cpt a#{avatar_id}", src: "/s/#{80 * V.view.attributes.pixel_ratio}/#{avatar_id}.#{V.view.attributes.img_format}" }
        else
          div { className: "toastie-background-image toastie-#{toast.level}-image" }
      div { className: "toastie-col2" },
        if toast.title?
          div { className: 'toastie-title' }, toast.title
        if toast.message?
          div { className: 'toastie-message'}, toast.message
        if toast.raw_message?
          div { className: 'toastie-message', dangerouslySetInnerHTML: { __html: toast.raw_message } }

Toast = React.createFactory ToastClass

#ToastieClass = createReactClass
class ToastieClass extends React.Component

  displayName: 'Toastie'

  # setState is async and mutiple notifys where over writing each other.
  # using this.toasts and this.next_id instead.
  
  #getInitialState: ->
  #  toasts: []
  #  next_id: 1
  
  constructor: (props) ->
    super(props)
    @toasts = []
    @next_id = 1

  componentDidMount: ->

    window.toastie = @
    # now replay any toasts in _ts
    next_tick ->
      if window._ts?
        toastie.add(t...) for t in window._ts

  info: (mesg, title, args) -> @add('info', mesg, title, args)

  warning: (mesg, title, args) -> @add('warning', mesg, title, args)

  # use toasting.warning, not 'warn'
  warn: (mesg, title, args) -> @add('warning', mesg, title, args)

  error: (mesg, title, args) -> @add('error', mesg, title, args)

  success: (mesg, title, args) -> @add('success', mesg, title, args)

  set_timeout: (toast) ->
    if toast.timeOut != 0
      toast.timer_id = setTimeout =>
        @remove(toast.id)
      , toast.timeOut
    else
      toast.timer_id = setTimeout =>
        @remove(toast.id)
      , 60000

  add: (level, mesg, title, args = {}) ->
    this_id = @next_id

    my_top = if @toasts.length and l = @toasts[@toasts.length - 1]
      l.top + l.height + 10
    else
      if window.device?.platform is 'iOS'
        44
      else
        10

    this_toast =
      id: this_id
      top: my_top
      level: level
      height: 150    # default value
      message: mesg
      title: title
      timeOut: args.timeOut ? 3000
      avatar_id: args.avatar_id
      raw_message: args.raw_message

    @toasts = @toasts.concat(this_toast)
    @next_id =  @next_id + 1

    @forceUpdate()

    @set_timeout this_toast

    return this_id

  replace: (id, mesg, title, args = {} ) ->
    if existing = _.find(@toasts, ( (t) -> t.id is id))
      existing.message = mesg
      existing.title = title
      existing.timeOut = args.timeOut ? 3000
      if args.level
        existing.level = args.level
      clearTimeout(existing.timer_id)
      @set_timeout existing
      @forceUpdate()
      return id
    else
      @add(args.level ? 'info', mesg, title, args)

  remove: (id) ->
    # mounted check OFF FOR NOW# if @isMounted()
    @toasts = @toasts.filter (t) => t.id isnt id
    @forceUpdate()

  remove_all: ->
    @toasts = []
    @forceUpdate()

  render: ->

    next_top = 10
    fragment null,
      for toast in @toasts
        this_top = next_top
        next_top = this_top + toast.height + 10
        toast.next_top = next_top
        Toast { toast: toast, key: toast.id, parent: @ }

Toastie = React.createFactory ToastieClass

export default Toastie

