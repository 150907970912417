$ = jQuery
$document =  $(document)

window.Jtip =
  bodyWidth: 1024
  msgHeight: 100
  showing: false

  normal: -> Jtip.start(300)
  delayed: -> Jtip.start(1100)

  start: (delay) ->

    Jtip.$jtip = $('<div id="jtip"><div class="arrow"></div><div class="tip_message" role="tooltip">Tooltip</div></div>')
    Jtip.$arrow = Jtip.$jtip.find('div.arrow')
    Jtip.$message = Jtip.$jtip.find('div.tip_message')
    Jtip.$body = $('body')

    Jtip.$jtip.css
      opacity: 0
      top: 0
      left: 0
    Jtip.$jtip.appendTo('body')
    Jtip.delay = delay
    
    $document.on 'mouseenter.jtip', '.jtip', Jtip.on
    $document.on "mousemove", '.jtip', Jtip.save_or_set_pos
    document.addEventListener 'scroll', Jtip.off
    $document.on "mouseleave", '.jtip', Jtip.off
    $document.on "click", '.jtip', Jtip.off

  on: (e) ->
    $tipped = $(this)
    Jtip.$jtip.css
      opacity: 0
      'z-index': -1
    if dt = $tipped.attr('data-title')
      Jtip.$message.html dt
    else if (df = $tipped.attr("data-function")) and (da = $tipped.attr("data-arg"))
      Jtip.$message.html window[df].call(window,da)

    Jtip.msgHeight = Jtip.$message.height()
    Jtip.bodyWidth = Jtip.$body.width()
    Jtip.save_or_set_pos(e)
    if Jtip.showing
      Jtip.$jtip.css
        opacity: 1
        'z-index': 1111
    unless ( Jtip.showing or Jtip.delayed_timer? )
      Jtip.delayed_timer = window.setTimeout(Jtip.delayed_on, Jtip.delay)
  
  delayed_on: ->
    Jtip.msgHeight = Jtip.$message.height()
    Jtip.showing = true
    Jtip.save_or_set_pos()
    Jtip.$jtip.css
      opacity: 1
      'z-index': 1111

  off: ->
    if Jtip.showing
      Jtip.showing = false
      Jtip.$jtip.css
        opacity: 0
        'z-index': -1
    if Jtip.delayed_timer?
      window.clearTimeout Jtip.delayed_timer
      Jtip.delayed_timer = null
  
  save_or_set_pos: (e) ->
    if not Jtip.showing
      Jtip.e = { pageX: e.pageX, pageY: e.pageY, clientY: e.clientY }
      return

    if not e?
      e = Jtip.e

    return unless e?

    if e.clientY > (window.innerHeight - 100 )
      #pointer is near bottom of page, place tip above
      arrow_css =
        transform: 'rotate(180deg)'
        bottom: '-20px'
        top: 'auto'
      yoffset = - (40 + Jtip.msgHeight )
    else
      arrow_css =
        transform: 'unset'
        bottom: 'auto'
        top: 0
      yoffset = 20

    if e.pageX > (Jtip.bodyWidth - 300)
      arrow_css.right = '3px'
      arrow_css.left = 'auto'
      Jtip.$arrow.css arrow_css
      
      Jtip.$jtip.css
        left: 'auto'
        right: Jtip.bodyWidth - ( e.pageX  ) - 12
        top: e.pageY + yoffset
    else
      arrow_css.left = "3px"
      arrow_css.right = 'auto'
      Jtip.$arrow.css arrow_css
      
      Jtip.$jtip.css
        left: e.pageX - 10
        right: 'auto'
        top: e.pageY + yoffset


window.jready -> Jtip.normal() # wait until document is ready

