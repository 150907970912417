
import * as Sentry from '@sentry/browser';



Sentry.init({
  dsn: "https://2167231570d74901b73bddb0cebf0be8@sentry.io/6536",
  
  release: '22062a06714f9ec46b9679c4a0691ca4109177de',
  
  linesOfContext: 500, 
  autoSessionTracking: false,
  environment: 'staging',
  ignoreErrors: [ 'DOMException: WRONG_DOCUMENT_ERR', 'Non-Error promise rejection captured', 'EvalError' ]
});


window.Sentry = Sentry

