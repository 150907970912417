R.BsModal = class BsModal extends Backbone.Model

        
  # example attrs, body: redered-component, size: 'modal-lg' or 'modal-sm'
  open: (attrs = {}) ->
    # check for exising open modal and it's close callback...
    if @attributes.show_modal and @attributes.close_call_back
      @attributes.close_call_back()

    # set/reset default values...
    attrs.size ?= null
    attrs.close_call_back ?= null
    attrs.show_modal = true
    attrs.no_click_close ?= null

    @set attrs
    @trigger('view_update')

  close: ->
    @attributes.close_call_back() if @attributes.close_call_back
    @clear()
    @trigger('view_update')


R.bs_modal = new R.BsModal()  # global singleton instance

