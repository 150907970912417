
$ = jQuery

jQuery.fn.jlSlider = (params) ->
  $s = this
  $s.children("div:first-child").show()
  $s.children("div:not(:first-child)").hide()
  ca = []
  $s.children("div").each ->
    ca.push $(this)

  next_slide = ->
    ca[0].fadeOut 300, ->
      ca[1].fadeIn 300
      ca.push ca.shift()

  interval_id = window.setInterval next_slide, 3500

