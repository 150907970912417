
start_bs_modal = ->
  return if V.bs_modal_ref
  unless window.bs_modal_spot?
    tb = document.createElement('div')
    tb.setAttribute('id', 'bs_modal_spot')
    document.body.appendChild(tb)
  root = createRoot(window.bs_modal_spot)
  V.bs_modal_ref = React.createRef()
  root.render(BsModal(ref: V.bs_modal_ref))

jready(start_bs_modal)


# currently assume R.bs_modal backbone imported elsewhere

import BsModal from './jsx/bs_modal.js'

